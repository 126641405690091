import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import '../assets/scss/theme/success.scss';
import '../assets/scss/bootstrap.scss';
import '../assets/scss/app.scss';

import 'react-toastify/dist/ReactToastify.css';
import '../assets/style/antd.css';
import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/popover/style/index.css';
import 'antd/lib/pagination/style/index.css';
import 'antd/lib/collapse/style/index.css';
import 'antd/lib/spin/style/index.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import routes from '../routes';
import Layout from '../layout';
import Notify from '../services/notify';
import AuthProvider from '../provider/AuthProvider';
import configureStore from '../redux/store';

const preloadedState = getSerializedData('preloaded_state');
const store = configureStore(preloadedState);

Notify.notifications.subscribe(alert => alert instanceof Function && alert());

function renderApp() {
	ensureReady(routes).then(data =>
		hydrate(
			<BrowserRouter>
				<Provider store={store}>
					<AuthProvider>
						<Layout>
							<CookiesProvider>
								<After data={data} routes={routes} store={store} />
							</CookiesProvider>
						</Layout>
					</AuthProvider>
				</Provider>
			</BrowserRouter>,
			document.getElementById('root')
		)
	);
}

renderApp();

if (module.hot) {
	module.hot.accept('../routes', renderApp);
}
