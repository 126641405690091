import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/bg.png';
import { APP_API } from '../services/constants';
import { useAuth } from '../hooks/auth';
import {
	hasViewAppointmentsPermission,
	hasViewDoctorsPermission,
	hasViewLabRequestsPermission,
	hasViewLabTestsPermission,
	hasViewPermissionsPermission,
	hasViewPharmacyPermission,
	hasViewPharmacyRequestsPermission,
	hasViewRolesPermission,
	hasViewTransactionsPermission,
	hasViewUsersPermission,
} from '../services/permissions';

const Aside = () => {
	const location = useLocation();

	const current = location.pathname;

	const { user } = useAuth();

	return (
		user && (
			<div id="aside" className="app-aside fade box-shadow-x white nav-expand white">
				<div className="sidenav modal-dialog dk white">
					<div className="navbar d-lg-none dark">
						<Link to="/" className="navbar-brand">
							<svg viewBox="0 0 24 24" height="28" width="28" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 0h24v24H0z" fill="none"></path>
								<path
									d="M19.51 3.08L3.08 19.51c.09.34.27.65.51.9.25.24.56.42.9.51L20.93 4.49c-.19-.69-.73-1.23-1.42-1.41zM11.88 3L3 11.88v2.83L14.71 3h-2.83zM5 3c-1.1 0-2 .9-2 2v2l4-4H5zm14 18c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-2l-4 4h2zm-9.71 0h2.83L21 12.12V9.29L9.29 21z"
									fill="#fff"
									className="fill-theme"
								></path>
							</svg>
							<img src={logo} alt="." className="hide" />
							<span className="hidden-folded d-inline">DEDA</span>
						</Link>
					</div>
					<div className="flex hide-scroll">
						<div className="scroll">
							<div
								className="py-3 light lt"
								style={{
									backgroundImage: `url(${bg})`,
									backgroundSize: 'cover',
								}}
							>
								<div className="nav-fold px-2">
									<Link className="d-flex p-2" to="/">
										<span className="avatar w-40 rounded grey hide">{user.profile.shortname}</span>
										<img
											src={`${APP_API}/avatar/${user.profile.profile_pic}`}
											alt=""
											className="w-40 circle"
										/>
									</Link>
									<div className="hidden-folded flex p-2">
										<div className="d-flex">
											<Link to="/" className="mr-auto text-nowrap">
												{user.profile.fullname}
												<small className="d-block text-muted">
													{user.role.slug === 'doctor' ? user.profile.speciality : user.role.name}
												</small>
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="nav-active-theme">
								<ul className="nav ">
									<li className="nav-header">
										<span className="text-xs hidden-folded">Main</span>
									</li>
									<li className={current === '/' ? 'active' : ''}>
										<Link to="/" className=" waves-effect">
											<span className="nav-icon">
												<i className="fas faa-layer-group" />
											</span>
											<span className="nav-text">Dashboard</span>
										</Link>
									</li>
									{hasViewRolesPermission(user.permissions) && (
										<li className={current === '/roles' ? 'active' : ''}>
											<Link to="/roles" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-sitemap" />
												</span>
												<span className="nav-text">Roles</span>
											</Link>
										</li>
									)}
									{hasViewPermissionsPermission(user.permissions) && (
										<li className={current === '/permissions' ? 'active' : ''}>
											<Link to="/permissions" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-key" />
												</span>
												<span className="nav-text">Permissions</span>
											</Link>
										</li>
									)}
									{hasViewUsersPermission(user.permissions) && (
										<li className={current === '/users' ? 'active' : ''}>
											<Link to="/users" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-users" />
												</span>
												<span className="nav-text">Users</span>
											</Link>
										</li>
									)}
									{hasViewDoctorsPermission(user.permissions) && (
										<li className={current === '/doctors' ? 'active' : ''}>
											<Link to="/doctors" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-user-md" />
												</span>
												<span className="nav-text">Doctors</span>
											</Link>
										</li>
									)}
									{hasViewAppointmentsPermission(user.permissions) && (
										<li className={current === '/appointments' ? 'active' : ''}>
											<Link to="/appointments" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-clock-o" />
												</span>
												<span className="nav-text">Appointments</span>
											</Link>
										</li>
									)}
									{hasViewLabTestsPermission(user.permissions) && (
										<li className={current === '/lab-tests' ? 'active' : ''}>
											<Link to="/lab-tests" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-hospital-o" />
												</span>
												<span className="nav-text">Lab Tests</span>
											</Link>
										</li>
									)}
									{hasViewPharmacyPermission(user.permissions) && (
										<li className={current === '/pharmacy' ? 'active' : ''}>
											<Link to="/pharmacy" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-flask" />
												</span>
												<span className="nav-text">Pharmacy</span>
											</Link>
										</li>
									)}
									{hasViewTransactionsPermission(user.permissions) && (
										<li className={current === '/transactions' ? 'active' : ''}>
											<Link to="/transactions" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-history" />
												</span>
												<span className="nav-text">Transactions</span>
											</Link>
										</li>
									)}
									{hasViewLabRequestsPermission(user.permissions) && (
										<li className={current === '/lab-requests' ? 'active' : ''}>
											<Link to="/lab-requests" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-history" />
												</span>
												<span className="nav-text">Lab Requests</span>
											</Link>
										</li>
									)}
									{hasViewPharmacyRequestsPermission(user.permissions) && (
										<li className={current === '/pharmacy-requests' ? 'active' : ''}>
											<Link to="/pharmacy-requests" className=" waves-effect">
												<span className="nav-icon">
													<i className="fa fa-history" />
												</span>
												<span className="nav-text">Pharmacy Requests</span>
											</Link>
										</li>
									)}
									<li className="pb-2 hidden-folded"></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default Aside;
