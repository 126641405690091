import { combineReducers } from 'redux';

import setting from './setting';
import user from './user';
import appointment from './appointment';
import cart from './cart';
import media from './media';

const reducers = combineReducers({
	setting,
	user,
	appointment,
	cart,
	media,
});

export default reducers;
