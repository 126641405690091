import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART, CLEAR_CART, LOAD_CART } from '../action-types';

const INITIAL_STATE = {
	items: [],
};

const cart = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOAD_CART:
			return { ...state, items: [...action.payload] };
		case ADD_TO_CART:
			return { ...state, items: [...state.items, action.payload] };
		case REMOVE_FROM_CART:
			const items = state.items.filter(
				cart =>
					!(cart.id === action.payload.id && cart.item_category === action.payload.item_category)
			);
			return { ...state, items: [...items] };
		case UPDATE_CART:
			const item = state.items.find(d => d.id === action.payload.id);
			if (item) {
				const index = state.items.findIndex(d => d.id === action.payload.id);

				return {
					...state,
					items: [
						...state.items.slice(0, index),
						{ ...item, ...action.payload },
						...state.items.slice(index + 1),
					],
				};
			}

			return state;

		case CLEAR_CART:
			return { ...state, items: [] };
		default:
			return state;
	}
};

export default cart;
