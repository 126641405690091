/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import moment from 'moment';

import { request } from '../services/utilities';
import { notifyError, notifySuccess } from '../services/notify';

const Notification = () => {
	const [notifications, setNotifications] = useState([]);
	const [isNotifDropped, setIsNotifDropped] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);

	const droppedClass = isNotifDropped ? 'show' : '';
	const hasPlural = notifications.length > 1 ? 's' : '';

	const removeItem = async id => {
		try {
			setIsRemoving(true);
			const config = { method: 'PUT' };
			const rs = await request(`notifications/${id}`, config);
			setNotifications([...notifications.filter(n => rs.notification.id !== n.id)]);
			setIsRemoving(false);
			notifySuccess('notification removed');
		} catch (e) {
			setIsRemoving(false);
			notifyError(e.message || 'could not remove notification');
			console.log(e);
		}
	};

	return (
		<li className={`nav-item dropdown mr-2 ${isNotifDropped ? 'show' : ''}`}>
			<a
				className="nav-link notify-link px-3"
				onClick={() => (isNotifDropped ? setIsNotifDropped(false) : setIsNotifDropped(true))}
			>
				<i className="fa fa-bell text-muted" />
				<span className={`badge badge-pill up ${notifications.length > 0 ? 'danger' : ''}`}>
					{notifications.length}
				</span>
			</a>
			<div
				className={`dropdown-menu dropdown-menu-right w-md animate fadeIn mt-2 p-0 ${droppedClass}`}
			>
				<div className="scrollable hover" style={{ maxHeight: '250px' }}>
					<div className="list">
						{notifications.map((item, i) => {
							return (
								item.seen === 0 && (
									<div className="list-item" key={i}>
										<div className="list-body">
											<a className="item-title _500" href="">
												{item.title}
											</a>
											<div className="item-except text-sm text-muted">{item.description}</div>
											<div className="text-date text-muted">
												{moment(item.created_at).fromNow()}
											</div>
										</div>
										<div className="item-close danger">
											<span className="text-sm" onClick={() => removeItem(item.id)}>
												<i className="fa fa-close" />
											</span>
										</div>
									</div>
								)
							);
						})}
					</div>
				</div>
				<div className="d-flex px-3 py-2 b-t">
					<div className="flex">
						<span>{`${notifications.length}  Notification${hasPlural}`}</span>
					</div>
				</div>
			</div>
		</li>
	);
};

export default Notification;
