/* eslint-disable jsx-a11y/anchor-is-valid */
import numeral from 'numeral';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { Field } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';

import { API_URI, TOKEN_COOKIE } from './constants';
import LocalStorage from './storage';

const cookies = new Cookies();

export const isUnset = o => typeof o === 'undefined' || o === null;

export function encodeValue(val) {
	if (typeof val === 'string') {
		return val;
	}

	return JSON.stringify(val);
}

export function decodeValue(val) {
	if (typeof val === 'string') {
		try {
			return JSON.parse(val);
		} catch (_) {}
	}

	return val;
}

export const checkStatus = async response => {
	if (!response.ok) {
		if (response.statusText === 'Unauthorized') {
			try {
				// prettier-ignore
				(new LocalStorage()).removeItem(TOKEN_COOKIE);
				cookies.remove(TOKEN_COOKIE, { path: '/' });
			} catch (e) {}
		}
		const message = await response.text();
		const err = JSON.parse(message);
		throw Object.freeze({ message: err.message || err.error });
	}

	return response;
};

const parseJSON = response => response.json();

export async function request(endpoint, { body, ...customConfig } = {}) {
	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	// prettier-ignore
	const token = await (new LocalStorage()).getItem(TOKEN_COOKIE);

	if (token) {
		const jwt = `Bearer ${token}`;
		headers = { ...headers, Authorization: jwt };
	}

	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: { ...headers },
	};

	if (body) {
		config.body = JSON.stringify(body);
	}

	const response = await fetch(`${API_URI}/${endpoint}`, config);
	const result = await checkStatus(response);

	if (customConfig.signal && customConfig.signal.aborted) {
		throw Object.freeze({ message: 'request-aborted' });
	}

	return parseJSON(result);
}

export const upload = async (endpoint, config) => {
	// prettier-ignore
	const token = await (new LocalStorage()).getItem(TOKEN_COOKIE);
	const jwt = `Bearer ${token}`;
	const headers = { Authorization: jwt };
	const response = await fetch(`${API_URI}/${endpoint}`, { ...config, headers });
	const result = await checkStatus(response);
	return parseJSON(result);
};

export function updateImmutable(list, payload) {
	const data = list.find(d => d.id === payload.id);
	if (data) {
		const index = list.findIndex(d => d.id === payload.id);

		return [...list.slice(0, index), { ...data, ...payload }, ...list.slice(index + 1)];
	}

	return list;
}

export function formatCurrency(amount, abs) {
	return `₦${numeral(abs ? Math.abs(amount || 0) : amount).format('0,0.00')}`;
}

export function formatDate(date, format = 'YYYY-MM-DD') {
	return date ? moment(date).format(format) : '--';
}

export const itemRender = (_, type, originalElement) => {
	if (type === 'prev') {
		return <a>Previous</a>;
	}

	if (type === 'next') {
		return <a>Next</a>;
	}

	return originalElement;
};

const makeRandom = lengthOfCode => {
	let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
	let text = '';
	for (let i = 0; i < lengthOfCode; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
};

export const generateReference = () => {
	return `DD-${makeRandom(8)}`;
};

export const ErrorBlock = ({ name }) => (
	<Field
		name={name}
		subscription={{ touched: true, error: true }}
		render={({ meta: { touched, error } }) =>
			touched && error ? (
				<ul className="list-unstyled text-sm mt-1 mb-0 filled text-danger">
					<li className="parsley-required">{error}</li>
				</ul>
			) : null
		}
	/>
);

export const confirmAction = (action, payload, alertText, alertHead) => {
	confirmAlert({
		customUI: ({ onClose }) => {
			const onclick = async () => {
				action(payload);
				onClose();
			};

			return (
				<div className="custom-ui text-center">
					<h1 className="">{alertHead ? alertHead : 'Are you sure?'}</h1>
					<p>{alertText ? alertText : 'You want to delete this'}</p>
					<div>
						<button className="btn btn-danger" style={{ margin: '10px' }} onClick={onClose}>
							No
						</button>
						<button className="btn btn-primary" style={{ margin: '10px' }} onClick={onclick}>
							Yes
						</button>
					</div>
				</div>
			);
		},
	});
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const groupBy = function (xs, key) {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

export const parseFrequency = (frequency, duration) => {
	const s = duration > 1 ? 's' : '';
	const frequencies = [
		{ name: 'as-needed', value: 'As Needed' },
		{ name: 'at-night', value: `Night${s}` },
		{ name: 'immediately', value: 'Immediately' },
		{ name: 'hourly', value: `Hour${s}` },
		{ name: 'daily', value: `Day${s}` },
		{ name: 'weekly', value: `Week${s}` },
		{ name: 'monthly', value: `Month${s}` },
		{ name: 'quarterly', value: `Quarter${s}` },
		{ name: 'stat', value: 'Stat' },
	];

	const item = frequencies.find(f => f.name === frequency);

	if (frequency === 'as-needed' || item.value === 'immediately') {
		return item?.value || '';
	}

	return `for ${duration} ${item?.value || ''}`;
};

export function escapeXmlTags(value) {
	if (value) {
		let escapedValue = value.replace(new RegExp('<', 'g'), '&lt');
		escapedValue = escapedValue.replace(new RegExp('>', 'g'), '&gt');
		return escapedValue;
	}
}

export const formatFileNumber = data => {
	if (!data) {
		return '';
	}

	let formattedId = String(data.emr_id);
	let len = 7 - formattedId.length;
	while (len >= 0) {
		formattedId = '0' + formattedId;
		len--;
	}

	const legacyId =
		data.legacy_patient_id && data.legacy_patient_id !== '' ? ` [${data.legacy_patient_id}]` : '';

	return `${formattedId}${legacyId}`;
};
