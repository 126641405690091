import React from 'react';

import interswitch from '../assets/images/interswitch.png';

const Footer = () => {
	return (
		<div className="content-footer white " id="content-footer">
			<div className="d-flex p-3">
				<span className="text-sm text-muted flex">
					&copy; {`Copyright ${new Date().getFullYear()}. Deda Hospital Powered by Spine Health`}
				</span>
				<div className="w footer-logo">
					<img src={interswitch} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Footer;
