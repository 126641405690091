// setting
export const TOGGLE_IS404 = 'TOGGLE_IS404';
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';

// user
export const SET_PROFILE = 'SET_PROFILE';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

// cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const LOAD_CART = 'LOAD_CART';

// appointment
export const SET_ACTIVE_APPOINTMENT = 'SET_ACTIVE_APPOINTMENT';
export const SET_CALL_STATUS = 'SET_CALL_STATUS';
export const SET_CALL_MESSAGE = 'SET_CALL_MESSAGE';

// media
export const SET_VIDEOROOM = 'SET_VIDEOROOM';
export const SET_LOCAL_CALLSTATUS = 'SET_LOCAL_CALLSTATUS';
export const SET_AUDIO = 'SET_AUDIO';
export const SET_VIDEO = 'SET_VIDEO';
