import { SET_PROFILE, SIGN_OUT, UPDATE_PROFILE } from '../action-types';

const INITIAL_STATE = {
	profile: null,
	loggedIn: false,
};

const user = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_PROFILE:
			return { ...state, profile: action.payload, loggedIn: action.status };
		case SIGN_OUT:
			return {
				...state,
				profile: null,
				loggedIn: false,
			};
		case UPDATE_PROFILE:
			return {
				...state,
				profile: { ...state.profile, profile: { ...state.profile.profile, ...action.payload } },
			};
		default:
			return state;
	}
};

export default user;
