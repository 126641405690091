import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../hooks/auth';
import { loginUser, signOut } from '../redux/actions/user';
import { TOKEN_COOKIE } from '../services/constants';
import { notifySuccess } from '../services/notify';
import LocalStorage from '../services/storage';
import { setActive } from '../redux/actions/appointment';
import { disconnectSocket, initSocket, subscribeIO } from '../services/socket';

const storage = new LocalStorage();

const AuthProvider = ({ children }) => {
	const user = useSelector(state => state.user.profile);

	const dispatch = useDispatch();

	const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie, removeCookie] = useCookies([TOKEN_COOKIE]);

	const setTokens = data => {
		try {
			const { appointment, ...user } = data.user;
			dispatch(loginUser(user));
			dispatch(setActive(appointment));
			storage.setItem(TOKEN_COOKIE, data.token);
			setCookie(TOKEN_COOKIE, data.token, { path: '/' });
			initSocket();
			subscribeIO();
		} catch (e) {
			console.log(e);
		}
	};

	const doSignOut = () => {
		try {
			dispatch(signOut());
			storage.removeItem(TOKEN_COOKIE);
			removeCookie(TOKEN_COOKIE, { path: '/' });
			disconnectSocket();
			notifySuccess('user logged out!');
			history.replace('/login');
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<AuthContext.Provider value={{ user, setAuthUser: setTokens, signOut: doSignOut }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
