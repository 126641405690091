export function hasViewRolesPermission(permissions) {
	return permissions.find(p => p === 'view-roles');
}

export function hasViewPermissionsPermission(permissions) {
	return permissions.find(p => p === 'view-permissions');
}

export function hasViewUsersPermission(permissions) {
	return permissions.find(p => p === 'view-users');
}

export function hasViewDoctorsPermission(permissions) {
	return permissions.find(p => p === 'view-doctors');
}

export function hasViewAppointmentsPermission(permissions) {
	return permissions.find(p => p === 'view-appointments');
}

export function hasViewLabTestsPermission(permissions) {
	return permissions.find(p => p === 'view-lab-tests');
}

export function hasViewPharmacyPermission(permissions) {
	return permissions.find(p => p === 'view-drugs');
}

export function hasViewTransactionsPermission(permissions) {
	return permissions.find(p => p === 'view-transactions');
}

export function hasViewLabRequestsPermission(permissions) {
	return permissions.find(p => p === 'view-lab-requests');
}

export function hasViewPharmacyRequestsPermission(permissions) {
	return permissions.find(p => p === 'view-pharmacy-requests');
}

// users
export function hasAddUserPermission(permissions) {
	return permissions.find(p => p === 'can-add-user');
}

export function hasEditUserPermission(permissions) {
	return permissions.find(p => p === 'can-edit-user');
}

export function hasViewHistoryPermission(permissions) {
	return permissions.find(p => p === 'can-view-appointment-history');
}
