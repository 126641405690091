import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Notification from './Notification';
import { APP_API } from '../services/constants';
import { useAuth } from '../hooks/auth';

const Header = () => {
	const [isDropped, setIsDropped] = useState(false);

	const { user } = useAuth();

	return (
		<div className="content-header white dark" id="content-header">
			<div className="navbar navbar-expand-lg">
				<a className="d-lg-none mx-2">
					<svg height="24" viewBox="0 0 512 512" width="24" xmlns="http://www.w3.org/2000/svg">
						<path d="M80 304h352v16H80zM80 248h352v16H80zM80 192h352v16H80z"></path>
					</svg>
				</a>
				<div className="navbar-text nav-title">
					<Link to={`/`}>
						<small className="ml-4 text-muted">Deda Hospital</small>
					</Link>
				</div>
				<div className="collapse navbar-collapse order-lg-1"></div>
				{user && (
					<ul className="nav flex-row order-lg-2">
						<Notification />
						<li className={`dropdown d-flex align-items-center ${isDropped ? 'show' : ''}`}>
							<a
								className="d-flex align-items-center"
								onClick={() => (isDropped ? setIsDropped(false) : setIsDropped(true))}
							>
								<span className="avatar w-32">
									<img alt="" src={`${APP_API}/avatar/${user.profile.profile_pic}`} />
								</span>
							</a>
							<div
								className={`dropdown-menu dropdown-menu-right w pt-0 mt-2 animate fadeIn ${
									isDropped ? 'show' : ''
								}`}
							>
								<Link
									className="dropdown-item"
									to={`/profile/${user.username}`}
									onClick={() => setIsDropped(false)}
								>
									<span>Profile</span>
								</Link>
								<div className="dropdown-divider"></div>
								<Link className="dropdown-item" onClick={() => setIsDropped(false)} to="/support">
									Need help?
								</Link>
								<Link className="dropdown-item" onClick={() => setIsDropped(false)} to="/logout">
									Sign out
								</Link>
							</div>
						</li>
					</ul>
				)}
			</div>
		</div>
	);
};

export default Header;
