import { SET_PROFILE, SIGN_OUT, UPDATE_PROFILE } from '../action-types';

export const loginUser = user => {
	return {
		type: SET_PROFILE,
		payload: user,
		status: true,
	};
};

export const signOut = () => {
	return {
		type: SIGN_OUT,
	};
};

export const updateUser = user => {
	return {
		type: UPDATE_PROFILE,
		payload: user,
	};
};
