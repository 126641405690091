import { SET_ACTIVE_APPOINTMENT, SET_CALL_MESSAGE, SET_CALL_STATUS } from '../action-types';

export const setActive = data => {
	return {
		type: SET_ACTIVE_APPOINTMENT,
		payload: data,
	};
};

export const setStatus = data => {
	return {
		type: SET_CALL_STATUS,
		payload: data,
	};
};

export const setMessage = data => {
	return {
		type: SET_CALL_MESSAGE,
		payload: data,
	};
};
