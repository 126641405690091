export const HOST = process.env.HOST;
export const DEBUG = process.env.RAZZLE_DEBUG;
export const APP_NAME = process.env.RAZZLE_APP_NAME;
export const APP_API = process.env.RAZZLE_API_URI;
export const API_VERSION = process.env.RAZZLE_API_VERSION;
export const VAT_FEE = process.env.RAZZLE_APP_VAT_FEE;
export const PAYSTACK_PK = process.env.RAZZLE_APP_PAYSTACK_PK;

export const API_URI = `${APP_API}/${API_VERSION}`;

export const TOKEN_COOKIE = 'tm:token';
export const ACCEPT_COOKIE = 'tm:accept';

export const JANUS_SERVER = process.env.RAZZLE_APP_JANUS_SERVER;
export const JANUS_SECRET = process.env.RAZZLE_APP_JANUS_SECRET;

export const limit = 10;
export const vatFee = VAT_FEE / 100;
export const sideWidth = 500;

export const servers = [
	{
		urls: 'turn:stream.dedahospital.com:3478',
		username: 'admin',
		credential: '2jmj7l5rSw0yVbvlWAYkK_YBwk',
	},
	{
		urls: 'stun:stream.dedahospital.com:3478',
		username: 'admin',
		credential: '2jmj7l5rSw0yVbvlWAYkK_YBwk',
	},
];
