import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import Splash from '../views/Splash';
import Header from '../components/Header';
import AdminHeader from '../components/AdminHeader';
import Aside from '../components/Aside';
import Footer from '../components/Footer';
import { initSocket, subscribeIO } from '../services/socket';

const Layout = ({ children, title = 'Deda Hospital' }) => {
	const [loading, setLoading] = useState(true);
	const [connected, setConnected] = useState(false);

	const { loggedIn, profile } = useSelector(state => state.user);
	const is404 = useSelector(state => state.setting.is404);

	const is_user = profile?.role.slug === 'user';

	useEffect(() => {
		if (loading) {
			setTimeout(() => {
				setLoading(false);
			}, 1500);
		}
	}, [loading]);

	useEffect(() => {
		if (!connected && loggedIn) {
			initSocket();
			subscribeIO();

			setConnected(true);
		}
	}, [connected, loggedIn]);

	return loading ? (
		<Splash />
	) : (
		<>
			{loggedIn && !is_user && !is404 && <AdminHeader />}
			<div className="app" id="app">
				<ToastContainer autoClose={3500} />
				<Helmet>
					<title>{title}</title>
				</Helmet>
				{!loggedIn ? (
					<div className="d-flex flex-column flex">{children}</div>
				) : (
					<>
						{!is404 ? (
							<>
								{!is_user && <Aside profile={profile} />}
								<div className={`app-content box-shadow-${is_user ? '0' : '3'}`} id="content">
									{is_user && <Header />}
									{children}
									<Footer />
								</div>
							</>
						) : (
							<div className="d-flex flex-column flex">{children}</div>
						)}
					</>
				)}
			</div>
			<div className="custom-backdrop fade"></div>
		</>
	);
};

export default Layout;
