import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import Notification from './Notification';
import { APP_API } from '../services/constants';
import { useAuth } from '../hooks/auth';

const AdminHeader = () => {
	const [isDropped, setIsDropped] = useState(false);

	const { user } = useAuth();

	const droppedClass = isDropped ? 'show' : '';

	return (
		<header className="app-header dark box-shadow-6">
			<div className="navbar navbar-expand-lg">
				<a className="d-lg-none mx-2">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
						<path d="M80 304h352v16H80zM80 248h352v16H80zM80 192h352v16H80z"></path>
					</svg>
				</a>
				<Link to="/" className="navbar-brand">
					<svg viewBox="0 0 24 24" height="28" width="28" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 0h24v24H0z" fill="none"></path>
						<path
							d="M19.51 3.08L3.08 19.51c.09.34.27.65.51.9.25.24.56.42.9.51L20.93 4.49c-.19-.69-.73-1.23-1.42-1.41zM11.88 3L3 11.88v2.83L14.71 3h-2.83zM5 3c-1.1 0-2 .9-2 2v2l4-4H5zm14 18c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-2l-4 4h2zm-9.71 0h2.83L21 12.12V9.29L9.29 21z"
							fill="#fff"
							className=""
						></path>
					</svg>
					<img src={logo} alt="." className="hide" />
					<span className="hidden-folded d-inline">DEDA</span>
				</Link>
				<ul className="nav flex-row order-lg-2">
					<Notification />
					<li className={`dropdown d-flex align-items-center ${droppedClass}`}>
						<a
							className="d-flex align-items-center"
							onClick={() => (isDropped ? setIsDropped(false) : setIsDropped(true))}
						>
							<span className="avatar w-32">
								<img src={`${APP_API}/avatar/${user.profile.profile_pic}`} alt="" />
							</span>
						</a>
						<div
							className={`dropdown-menu dropdown-menu-right w pt-0 mt-2 animate fadeIn ${droppedClass}`}
						>
							<Link
								className="dropdown-item"
								to={`/profile/${user.email}`}
								onClick={() => setIsDropped(false)}
							>
								<span>Profile</span>
							</Link>
							<div className="dropdown-divider"></div>
							<Link className="dropdown-item" onClick={() => setIsDropped(false)} to="/logout">
								Sign out
							</Link>
						</div>
					</li>
				</ul>
				<div className="collapse navbar-collapse order-lg-1">
					<ul className="navbar-nav mt-2 mt-lg-0 mx-0 mx-lg-2 ">
						<li className="nav-item">
							<Link to="/" className="nav-link">
								Dashboard
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</header>
	);
};

export default AdminHeader;
