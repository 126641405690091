import { SET_ACTIVE_APPOINTMENT, SET_CALL_MESSAGE, SET_CALL_STATUS } from '../action-types';

const INITIAL_STATE = {
	list: [],
	active: null,
	callStatus: '',
	callMessage: '',
};

const appointment = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_ACTIVE_APPOINTMENT:
			return { ...state, active: action.payload };
		case SET_CALL_STATUS:
			return { ...state, callStatus: action.payload };
		case SET_CALL_MESSAGE:
			return { ...state, callMessage: action.payload };
		default:
			return state;
	}
};

export default appointment;
