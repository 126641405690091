import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';

import reducers from '../redux/reducers';

let middlewares = [thunk];

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
	const logger = createLogger({
		collapsed: true,
	});
	middlewares = [...middlewares, logger];
}

const composedEnhancers = composeWithDevToolsLogOnlyInProduction({});

export default function configureStore(preloadedState) {
	const middlewareEnhancer = applyMiddleware(...middlewares);

	const store = createStore(reducers, preloadedState, composedEnhancers(middlewareEnhancer));

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers').default;
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
}
