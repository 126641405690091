import { updateImmutable } from '../../services/utilities';
import { ADD_ROLE, TOGGLE_IS404, UPDATE_ROLE } from '../action-types';

const INITIAL_STATE = {
	is404: false,
	accepted: false,
	countries: [],
	roles: [],
};

const general = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TOGGLE_IS404:
			return { ...state, is404: action.payload };
		case ADD_ROLE:
			return { ...state, roles: [...state.roles, action.payload] };
		case UPDATE_ROLE:
			return { ...state, roles: updateImmutable(state.roles, action.payload) };
		default:
			return state;
	}
};

export default general;
