import { io } from 'socket.io-client';

import { APP_API } from './constants';
import { messageService } from './message';

let socket;

export const initSocket = () => {
	console.log('socket initiatied');

	socket = io(`${APP_API}/socket`, { transports: ['websocket', 'polling'] });

	if (socket && !socket?.connected) {
		socket.on('connect', () => {
			console.log(`connected to socket.io: ${socket.connected}: ${socket.id}`);
		});

		socket.io.on('reconnect', () => {
			console.log(`re-connected to socket.io`);
		});

		socket.on('disconnect', reason => {
			console.log(`user disconnected: ${reason}`);
		});
	}
};

export const subscribeIO = () => {
	if (socket) {
		// transaction paid
		socket.on('transaction:paid', data => {
			messageService.sendMessage({ type: 'paid', data });
		});

		// video call from doctor
		socket.on('start:call', data => {
			messageService.sendMessage({ type: 'call', data });
		});
	}
};

export const emit = (event, data) => {
	if (socket) {
		socket.emit(event, data);
	}
};

export const disconnectSocket = () => {
	if (socket) {
		socket.disconnect();
	}
};

export const getSocket = () => socket;
