/* eslint-disable import/no-anonymous-default-export */
import { asyncComponent } from '@jaredpalmer/after';

import Placeholder from './components/Placeholder';

export default [
	{
		path: '/',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Home'),
			Placeholder,
		}),
	},
	{
		path: '/roles',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Roles'),
			Placeholder,
		}),
	},
	{
		path: '/permissions',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Permissions'),
			Placeholder,
		}),
	},
	{
		path: '/users',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Users'),
			Placeholder,
		}),
	},
	{
		path: '/doctors',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Doctors'),
			Placeholder,
		}),
	},
	{
		path: '/appointments',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Appointments'),
			Placeholder,
		}),
	},
	{
		path: '/login',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Login'),
			Placeholder,
		}),
	},
	{
		path: '/register',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Register'),
			Placeholder,
		}),
	},
	{
		path: '/forgot-password',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/ForgotPassword'),
			Placeholder,
		}),
	},
	{
		path: '/reset-password/:token',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/ResetPassword'),
			Placeholder,
		}),
	},
	{
		path: '/profile/:username',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Profile'),
			Placeholder,
		}),
	},
	{
		path: '/logout',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Logout'),
			Placeholder,
		}),
	},
	{
		path: '/transactions',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Transactions'),
			Placeholder,
		}),
	},
	{
		path: '/lab-tests',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Lab'),
			Placeholder,
		}),
	},
	{
		path: '/pharmacy',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Pharmacy'),
			Placeholder,
		}),
	},
	{
		path: '/lab-requests',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/LabRequests'),
			Placeholder,
		}),
	},
	{
		path: '/support',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Support'),
			Placeholder,
		}),
	},
	{
		path: '/terms-and-conditions',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/Terms'),
			Placeholder,
		}),
	},
	{
		path: '/privacy-policy',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/PrivacyPolicy'),
			Placeholder,
		}),
	},
	{
		path: '/pharmacy-requests',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/PharmacyRequests'),
			Placeholder,
		}),
	},
	{
		path: '/not-found',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/NotFound'),
			Placeholder,
		}),
	},
	{
		path: '*',
		exact: true,
		component: asyncComponent({
			loader: () => import('./views/NotFound'),
			Placeholder,
		}),
	},
];
