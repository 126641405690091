import { SET_AUDIO, SET_LOCAL_CALLSTATUS, SET_VIDEO, SET_VIDEOROOM } from '../action-types';

const INITIAL_STATE = {
	localCallStatus: '',
	audio: true,
	video: true,
	videoroom: null,
};

const media = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_VIDEOROOM:
			return { ...state, videoroom: action.payload };
		case SET_LOCAL_CALLSTATUS:
			return { ...state, localCallStatus: action.payload };
		case SET_AUDIO:
			return { ...state, audio: action.payload };
		case SET_VIDEO:
			return { ...state, video: action.payload };
		default:
			return state;
	}
};

export default media;
